import React from "react";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import { useAlternateLangs } from "../../components/Hreflangs";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";

import H from "../../components/Headline";

const breadCrumbLevels = {
  Home: "/",
  "Privatlivspolitik": "da/privacy-policy"
};

// Hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/privacy-policy"
);

const PrivacyPolicy = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title="Privatlivspolitik"
        description="Detaljer om min datapolitik, der sigter mod at forklare, hvordan mine tjenester fungerer, og hvordan beskyttelsen af dine personlige data er garanteret."
        lang="da"
        alternateLangs={alternateLangs}
        meta={[{ name: "robots", content: "noindex" }]}
      />
      <MainContent article>
        <Breadcrumb breadCrumbLevels={breadCrumbLevels} />

        <H as="h1">Databeskyttelse</H>
        <p>Databeskyttelsespolitik</p>
        <p>
          Nedenfor vil jeg gerne forklare, hvilke data jeg indsamler, behandler og bruger, hvornår og til hvilket formål. Formålet er at forklare dig, hvordan mine tjenester fungerer, og hvordan beskyttelsen af dine personlige data er garanteret i denne henseende.
        </p>
        <p>Jeg indsamler, behandler og bruger kun personlige data, hvor du har givet samtykke til det, eller lovgivningen tillader det.</p>
        <p>
          Denne datapolitik kan downloades, gemmes og udskrives til enhver tid via URL'en https://matthiaskupperschmidt.com/privacy-policy/.
        </p>
        <H as="h2">Dataansvarliges navn og adresse</H>
        <p>Den dataansvarlige i henhold til databeskyttelseslovgivningen er:</p>
        <p>Matthias Kupperschmidt</p>
        <p>Frederikssundsvej 22</p>
        <p>2400 København, Danmark</p>
        <p>Du kan kontakte mig når som helst via email på legal@matthiaskupperschmidt.com.</p>
        <H as="h2">Navn og adresse på databeskyttelsesansvarlig</H>
        <p>Kontaktoplysninger på den databeskyttelsesansvarlige:</p>
        <p>Matthias Kupperschmidt</p>
        <p>Frederikssundsvej 22</p>
        <p>2400 København, Danmark</p>
        <p>Du kan kontakte mig når som helst via email på legal@matthiaskupperschmidt.com.</p>
        <H as="h2">Generelle punkter om databeskyttelse</H>
        <p>Omfanget af behandling af personoplysninger</p>
        <p>
          Jeg behandler kun brugernes personoplysninger, hvor det er nødvendigt for at levere en operationel hjemmeside samt indhold og tjenester. Som regel behandles brugernes personoplysninger kun efter brugerens samtykke. En undtagelse gælder i sådanne tilfælde, hvor samtykke ikke kan opnås på forhånd af faktuelle årsager, og behandling af data er tilladt ved lovbestemmelser.
        </p>
        <H as="h2">Retligt grundlag for behandling af personoplysninger</H>
        <p>
          Artikel 6 (1, a) i EU's generelle databeskyttelsesforordning (GDPR) tjener som retsgrundlag, hvor jeg opnår en registrerets samtykke til behandling af personoplysninger.
        </p>
        <p>
          Artikel 6 (1, b) GDPR tjener som retsgrundlag, hvor behandlingen af personoplysninger er nødvendig for at opfylde en kontrakt, hvor den registrerede er part i kontrakten. Dette gælder også for behandling, der er nødvendig for at gennemføre prækontraktuelle foranstaltninger.
        </p>
        <p>
          Artikel 6 (1, c) GDPR tjener som retsgrundlag, hvor behandlingen af personoplysninger er nødvendig for at opfylde en juridisk forpligtelse, som mit firma er underlagt.
        </p>
        <p>
          Hvor behandlingen er nødvendig for at beskytte en legitim interesse for mit firma eller en tredjepart, og den registreredes interesser og grundlæggende rettigheder og friheder ikke opvejer den førstnævnte interesse, tjener artikel 6 (1, f) GDPR som retsgrundlag for behandlingen.
        </p>

        <H as="h2">Sletning og opbevaringsvarighed af data</H>
        <p>
          Den registreredes personoplysninger slettes eller blokeres, så snart formålet med opbevaringen ophører med at eksistere. Opbevaring kan også ske, hvis det er tilsigtet af europæiske eller nationale lovgivere i forordninger, love eller andre krav under EU-lovgivningen, som den dataansvarlige er underlagt. Data slettes også eller slettes, hvis en opbevaringsperiode foreskrevet af den nævnte lovgivning er udløbet, medmindre der er behov for fortsat opbevaring af dataene for at indgå eller opfylde en kontrakt.
        </p>
        <p>Typer af behandlede data:</p>
        <p>– Kontaktoplysninger (f.eks. email, navn).</p>
        <p>– Brugsdata (f.eks. besøgte sider, interesse for indhold, adgangstider).</p>
        <p>– Meta/kommunikationsdata (f.eks. enhedsinformation, IP-adresser).</p>
        <H as="h2">Kategorier af registrerede</H>
        <p>Besøgende og brugere af online tilbuddet, alle opsummeret som "brugere" herefter.</p>
        <H as="h2">Formål med behandlingen</H>
        <p>– Tilvejebringelse af online tilbuddet, dets funktioner og indhold.</p>
        <p>– Besvarelse af kontaktforespørgsler og kommunikation med brugere.</p>
        <p>– Sikkerhedsforanstaltninger.</p>
        <p>– Publikumsopmåling/marketing</p>
        <H as="h3">Anvendte definitioner</H>
        <p>
          'Personoplysninger' betyder enhver information vedrørende en identificeret eller identificerbar fysisk person ('den registrerede'); en identificerbar fysisk person er en, der kan identificeres, direkte eller indirekte, især ved henvisning til en identifikator såsom et navn, et identifikationsnummer, lokalitetsdata, en online identifikator (f.eks. cookie) eller til en eller flere faktorer, der er specifikke for den fysiske, fysiologiske, genetiske, mentale, økonomiske, kulturelle eller sociale identitet af denne fysiske person.
        </p>
        <p>
          'Behandling' betyder enhver operation eller sæt af operationer, der udføres på personoplysninger eller på sæt af personoplysninger, uanset om det sker ved automatiserede midler. Begrebet er omfattende og dækker praktisk talt enhver omgang med data.
        </p>
        <p>
          'Dataansvarlig' henviser til den fysiske eller juridiske person, offentlige myndighed, agentur eller anden instans, som alene eller sammen med andre bestemmer formålene og midlerne til behandlingen af personoplysninger.
        </p>
        <H as="h2">Relevante retsgrundlag</H>
        <p>
          I overensstemmelse med artikel 13 GDPR informerer jeg dig om de retsgrundlag for vores databehandling. Følgende gælder, hvor det juridiske grundlag ikke er angivet i datapolitikken: Retsgrundlaget for indhentning af samtykke er artikel 6 (1, a) og artikel 7 GDPR, retsgrundlaget for behandling for udførelse af vores tjenester og gennemførelse af kontraktlige foranstaltninger samt besvarelse af forespørgsler er artikel 6 (1, b) GDPR, retsgrundlaget for behandling for overholdelse af vores juridiske forpligtelser er artikel 6 (1, c) GDPR, og retsgrundlaget for behandling for beskyttelse af vores legitime interesser er artikel 6 (1, f) GDPR.
        </p>
        <H as="h2">Samarbejde med databehandlere og tredjeparter</H>
        <p>
          Hvor jeg ved behandling, afslører data til andre parter og virksomheder (databehandlere eller tredjeparter), eller giver direkte eller anden adgang til dataene, sker dette kun baseret på lovgivningsmæssig tilladelse (f.eks. hvis overførsel af data til tredjeparter, som en betalingstjenesteudbyder, er nødvendig for at udføre en kontrakt i overensstemmelse med artikel 6 (1, b) GDPR, du har givet samtykke, en juridisk forpligtelse er på plads, eller baseret på vores legitime interesser (f.eks. ved brug af agenter, webværter osv.).
        </p>
        <p>
          Hvor jeg bestiller tredjeparter til behandling af data baseret på en såkaldt 'behandlingsaftale', sker dette baseret på artikel 28 GDPR.
        </p>
        <H as="h2">Overførsel til tredjelande</H>
        <p>
          Hvor jeg behandler data i et tredjeland (dvs. uden for Den Europæiske Union (EU) eller Det Europæiske Økonomiske Samarbejdsområde (EØS)), eller dette sker som en del af brugen af tredjepartstjenester eller videregivelsen eller, som måtte være tilfældet, overførslen af data til tredjeparter, sker dette kun, hvis det er nødvendigt for at udføre vores (præ-)kontraktlige forpligtelser, baseret på dit samtykke, på grund af en juridisk forpligtelse, eller baseret på vores legitime interesser. Underlagt lovgivningsmæssig eller kontraktlig tilladelse behandler jeg kun, eller arrangerer, at data behandles i et tredjeland, hvor de specifikke krav i artikel 44 ff. GDPR er på plads. Med andre ord sker behandlingen, for eksempel, baseret på særlige garantier som den officielt anerkendte bestemmelse af databeskyttelsesniveauet svarende til EU (f.eks. for USA af 'Privacy Shield') eller overholdelse af officielt anerkendte specifikke forpligtelser (såkaldte 'standardkontraktbestemmelser').
        </p>
        <H as="h2">Rettigheder for registrerede</H>
        <p>
          Du har ret til at kræve bekræftelse af, om de pågældende data behandles, og for information om disse data, samt yderligere oplysninger og en kopi af dataene i overensstemmelse med artikel 15 GDPR.
        </p>
        <p>
          I overensstemmelse med artikel 16 GDPR har du ret til at kræve, at data, der vedrører dig, bliver fuldstændiggjort, eller at ukorrekte data, der vedrører dig, bliver rettet.
        </p>
        <p>
          I overensstemmelse med artikel 17 GDPR har du ret til at kræve sletning af data, der vedrører dig, uden unødig forsinkelse, eller alternativt i overensstemmelse med artikel 18 GDPR, begrænset behandling af dataene.
        </p>
        <p>
          Du har ret til at kræve modtagelse af data, som du har givet mig, i overensstemmelse med artikel 20 GDPR, og at anmode om deres overførsel til en anden dataansvarlig.
        </p>
        <p>
          Du har også ret i overensstemmelse med artikel 77 GDPR til at indgive en klage til den relevante tilsynsmyndighed.
        </p>
        <H as="h2">Tilbagekaldelse</H>
        <p>Du har ret til at tilbagekalde samtykke givet i overensstemmelse med artikel 7 (3) GDPR med fremtidig virkning.</p>
        <H as="h2">Ret til indsigelse</H>
        <p>
          I overensstemmelse med artikel 21 GDPR kan du gøre indsigelse mod fremtidig behandling af data, der vedrører dig. Indsigelsen kan især rettes mod behandling til direkte markedsføring.
        </p>
        <H as="h2">Cookies og retten til indsigelse mod direkte markedsføring</H>
        <p>
          Cookies henviser til små filer, der gemmes på brugernes computere. Forskellige oplysninger kan gemmes inden for cookies. En cookie tjener primært til at gemme oplysninger om en bruger (eller, som måtte gælde den enhed, hvorpå cookien er gemt) under eller endda efter hans/hendes besøg inden for et online tilbud. Cookies, der slettes, efter en bruger forlader et online tilbud og lukker deres browser, omtales som midlertidige cookies eller, som måtte gælde, sessionscookies. For eksempel kan en login-status gemmes i en sådan cookie. Cookies, der forbliver, selv efter browseren er blevet lukket, omtales som permanente eller vedvarende. For eksempel gør dette det muligt at gemme login-status, hvis brugeren besøger det efter flere dage. Brugernes interesser for måling af publikum eller markedsføringsformål kan også gemmes i en sådan cookie. Cookies, der tilbydes af andre udbydere end den dataansvarlige, der driver online tilbuddet, omtales som tredjepartscookies (ellers førsteparters cookies, hvis det kun er dets cookies, der findes).
        </p>
        <p>Jeg kan bruge midlertidige og permanente cookies og uddybe dette under vores datapolitik.</p>
        <p>
          Hvor brugere ikke ønsker, at cookies gemmes på deres computer, bedes de deaktivere den tilsvarende mulighed i systemindstillingerne. Gemte cookies kan slettes i browserens systemindstillinger. Ekskludering af cookies kan føre til begrænset funktionalitet af dette online tilbud.
        </p>
        <p>
          En generel indsigelse mod brugen af cookies placeret til online markedsføringsformål kan erklæres med en bred vifte af tjenester, især i tilfælde af tracking, via den amerikanske side http://www.aboutads.info/choices/ eller den europæiske side http://www.youronlinechoices.com/. Derudover kan lagring af cookies opnås ved at deaktivere dem i browserens indstillinger. Bemærk venligst, at ikke alle funktioner i dette online tilbud derefter kan bruges.
        </p>
        <p>
          Ved adgang til vores hjemmeside informeres brugerne om brugen af cookies til analyseformål via et infobanner, der henviser til datapolitikken. I denne forbindelse er der også en henvisning til, hvordan lagring af cookies kan forhindres i browserens indstillinger. &nbsp;Dette såkaldte cookie tracking-filter hjælper brugeren med at gemme, hvilke cookies der skal sættes. Hans/hendes egen cookie gemmes til dette formål. Hvis du sletter dine cookies, bør du ikke slette denne cookie, da cookie tracking-filteret ellers ikke kan genkende dine præferencer.
        </p>
        <H as="h2">Sletning af data</H>
        <p>
          Data behandlet af mig slettes i overensstemmelse med artikel 17 og 18 GDPR, eller behandlingen begrænses. Medmindre andet udtrykkeligt er angivet som en del af datapolitikken, slettes data, der er gemt hos mig, så snart det ikke længere er nødvendigt for dets formål, og ingen lovpligtige opbevaringspligter står i vejen for sletning. Hvor data ikke slettes, fordi det er nødvendigt til andre lovlige formål tilladt af lovgivningen, begrænses behandlingen. Med andre ord blokeres dataene og behandles ikke til andre formål. For eksempel gælder dette data, der skal opbevares i henhold til handels- eller skatteretten.
        </p>
        <H as="h2">Forretningsrelateret behandling</H>
        <p>Jeg behandler også</p>
        <p>– kontraktsdata (f.eks. kontraktobjekt, løbetid, kundekategori).</p>
        <p>– betalingsdata (f.eks. bankoplysninger, betalingshistorik)</p>
        <p>
          fra mine kunder, potentielle kunder og forretningspartnere med henblik på at levere kontraktlige tjenester, servicering og kundeservice, markedsføring, reklame og markedsundersøgelser.
        </p>
        <H as="h2">Hosting</H>
        <p>
          De hostingtjenester, jeg bruger, tjener til at levere følgende tjenester: Infrastruktur- og platformstjenester, computerkapacitet, hukommelsesplads og databasetjenester, og sikkerhedstjenester samt tekniske vedligeholdelsestjenester, som jeg bruger til driften af dette online tilbud.
        </p>
        <p>
          Herved behandler jeg eller, som måtte gælde, min hostingudbyder inventardata, kontaktoplysninger, indholdsdata, kontraktsdata, brugsdata, meta- og kommunikationsdata fra kunder og potentielle kunder af og besøgende på dette online tilbud baseret på vores legitime interesser i effektivt og sikkert at levere dette online tilbud i overensstemmelse med artikel 6 (1, f) GDPR i forbindelse med artikel 28 GDPR (indgåelse af en behandlingsaftale).
        </p>
        <H as="h2">Indsamling af adgangsdata og logfiler</H>
        <p>
          Baseret på min legitime interesse i henhold til artikel 6 (1, f) GDPR indsamler jeg eller, som måtte gælde, min hostingudbyder data om enhver adgang til serveren, hvor denne tjeneste er placeret (såkaldte serverlogfiler). Adgangsdata omfatter navnet på den tilgåede hjemmeside, fil, dato og tidspunkt for adgang, mængden af overførte data, rapport om vellykket adgang, browsertype sammen med version, brugerens operativsystem, referrer URL (tidligere besøgt side), IP-adresse og anmodende udbyder.
        </p>
        <p>
          Midlertidig opbevaring af IP-adressen af systemet er nødvendigt for at muliggøre levering af hjemmesiden til brugerens computer. Brugerens IP-adresse skal forblive gemt i sessionens varighed for at muliggøre dette.
        </p>
        <p>
          Opbevaring sker i logfiler for at sikre funktionaliteten af hjemmesiden, især til analyse af et angreb (DOS-angreb) og misbrug af de leverede værktøjer. &nbsp;Dataene tjener også til at optimere hjemmesiden og sikre sikkerheden af mine IT-systemer. Dataene evalueres ikke til markedsføringsformål i denne forbindelse.
        </p>
        <p>
          Dataene slettes, så snart det ikke længere er nødvendigt for at opnå formålet med indsamlingen. &nbsp;Dette er generelt tilfældet efter 30 dage.
        </p>
        <p>
          Indsamling af data til levering af hjemmesiden og opbevaring af data i logfiler er obligatorisk for driften af hjemmesiden. Som et resultat er brugeren ikke i stand til at gøre indsigelse.
        </p>
        <H as="h2">Levering af kontraktlige tjenester</H>
        <p>
          Jeg behandler inventardata (f.eks. navne og adresser samt kontaktoplysninger fra kunder) og kontraktsdata (f.eks. anvendte tjenester, navne på kontaktpersoner, betalingsoplysninger) med henblik på at opfylde mine kontraktlige forpligtelser og tjenester i overensstemmelse med artikel 6 (1, b) GDPR. Poster markeret som obligatoriske i onlineformularer er nødvendige for indgåelsen af kontrakten.
        </p>
        <p>
          Ved brug af mine online tjenester gemmer jeg IP-adressen og tidspunktet for den respektive brugeraktivitet. Opbevaring sker baseret på mine legitime interesser, samt for at beskytte brugeren mod misbrug og anden uautoriseret brug. Disse data videregives principielt ikke til tredjeparter, medmindre det kræves for at følge op på mine krav, eller der er en lovpligtig forpligtelse i denne henseende i overensstemmelse med artikel 6 (1, c) GDPR.
        </p>
        <p>
          Jeg behandler brugsdata (f.eks. besøgte sider på mit online tilbud, interesse for mine produkter) og indholdsdata (f.eks. indtastninger i kontaktformularen eller brugerprofilen) til reklameformål i en brugerprofil for at vise brugere, for eksempel, produktinformation baseret på tjenester, de tidligere har benyttet.
        </p>
        <p>
          Dataene slettes ved udløb af lovbestemte garantier og sammenlignelige forpligtelser, og behovet for at opbevare dataene kontrolleres hver tredje år. I tilfælde af lovbestemte arkiveringsforpligtelser sker sletning ved deres udløb. Oplysninger i enhver kundekonto forbliver, indtil den slettes.
        </p>
        <H as="h2">Administration, regnskab, kontororganisation, kontaktadministration</H>
        <p>
          Jeg behandler data som en del af administrative opgaver samt organisationen af min drift, regnskab og overholdelse af lovbestemte forpligtelser såsom arkivering. Som sådan behandler jeg de samme data, som jeg behandler, når jeg leverer mine kontraktlige tjenester. Begrundelsen for behandlingen er artikel 6 (1, c) GDPR og artikel 6 (1, f) GDPR. Kunder, potentielle kunder, forretningspartnere og besøgende på hjemmesiden er berørt af behandlingen. Formålet og interessen i behandlingen ligger i administrationen, regnskab, kontororganisation og arkivering af data, dvs. opgaver, der tjener til at opretholde mine forretningsaktiviteter, udføre mine opgaver og levere mine tjenester. Sletning af data vedrørende kontraktlige tjenester og kommunikation svarer til detaljerne angivet med disse behandlingsaktiviteter.
        </p>
        <p>
          Som sådan videregiver eller overfører jeg data til Skattevæsenet, rådgivere som revisorer eller revisorer og andre inkassatorer og betalingstjenesteudbydere.
        </p>
        <p>
          Derudover gemmer jeg baseret på mine kommercielle interesser oplysninger om leverandører, arrangører og andre forretningspartnere, for eksempel med henblik på efterfølgende kontakt. I princippet deler jeg disse data relateret til virksomheder på permanent basis.
        </p>
        <H as="h2">Forretningsanalyse og markedsundersøgelser</H>
        <p>
          For at kunne drive min virksomhed kommercielt og identificere markedstendenser samt kunders og brugeres ønsker, analyserer jeg de data, der er tilgængelige for mig om forretningsprocesser, kontrakter, forespørgsler osv. Herved behandler jeg inventardata, kommunikationsdata, kontraktsdata, betalingsdata, brugsdata og metadata baseret på artikel 6 (1, f) GDPR, hvor de registrerede inkluderer kunder, potentielle kunder, forretningspartnere og besøgende på og brugere af online tilbuddet.
        </p>
        <p>
          Analyse finder sted med henblik på forretningsvurdering, markedsføring og markedsundersøgelser. Dette giver mig mulighed for at overveje profiler af registrerede brugere med oplysninger som deres købsprocesser. Analyse tjener mig til at øge brugervenligheden og optimere mit tilbud og forretningsmæssige effektivitet. Analyse tjener kun mig og videregives ikke eksternt, medmindre det involverer anonymiseret analyse med opsummerede værdier.
        </p>
        <p>
          Hvor denne analyse eller profiler er personlige, slettes de eller anonymiseres ved opsigelsen af brugeren, ellers efter to år fra afslutningen af kontrakten. Derudover produceres generelle kommercielle analyser og generel bestemmelse af tendenser, hvor det er muligt.
        </p>
        <H as="h2">Email tilmeldingsfunktion</H>
        <p>
          Brugere har mulighed for at abonnere på mit indhold med deres email. Brugere forsynes med de nødvendige obligatoriske oplysninger ved registrering. Oplysninger indtastet ved registrering bruges til at drage fordel af tilbuddet. Brugere kan modtage emails vedrørende tilbud eller ændringer i omfanget af tilbuddet eller tekniske betingelser. Hvis brugere har annulleret deres brugerkonto, slettes deres data vedrørende brugerkontoen, medmindre opbevaring er påkrævet af kommercielle eller skattemæssige grunde i overensstemmelse med artikel 6 (1, c) GDPR. Det er op til brugerne at sikre deres data inden kontraktens afslutning i tilfælde af annullering. Jeg har ret til uigenkaldeligt at slette alle brugerens data gemt under kontrakten.
        </p>
        <p>
          Når mine registrerings- og loginfunktioner bruges, samt når brugerkontoen bruges, gemmer jeg IP-adressen og tidspunktet for den respektive brugeraktivitet. Opbevaring sker ikke kun baseret på mine legitime interesser, men også brugeren for at beskytte mod misbrug og anden uautoriseret brug. Disse data videregives principielt ikke til tredjeparter, medmindre det kræves for at følge op på mine krav, eller der er en lovpligtig forpligtelse i denne henseende i overensstemmelse med artikel 6 (1, c) GDPR.
        </p>
        <H as="h2">Kontakt</H>
        <p>
          Når du kontakter mig (for eksempel ved hjælp af kontaktformularen, email, telefon eller via sociale medier), behandles brugerens oplysninger til håndtering af kontaktforespørgslen og i overensstemmelse med artikel 6 (1, b) GDPR. Brugerens oplysninger kan gemmes i et kundeadministrationssystem (CRM-system) eller et tilsvarende forespørgselshåndteringssystem.
        </p>
        <p>
          Jeg sletter forespørgsler, så snart de ikke længere er nødvendige. Jeg gennemgår behovet hvert andet år, og lovpligtige arkiveringspligter gælder også.
        </p>
      </MainContent>
    </React.Fragment>

  </Layout>
);
export default PrivacyPolicy;